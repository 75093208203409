import React, { useState, forwardRef, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
// @mui
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { Box, Grid, Typography } from '@mui/material';
import { LiveChatWidget } from '@livechat/widget-react';
import Iconify from '../../components/Iconify';
// hooks
import useSettings from '../../hooks/useSettings';
import useResponsive from '../../hooks/useResponsive';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// config
import { HEADER, NAVBAR } from '../../config';
// redux
import { useDispatch, useSelector } from '../../redux/store';
import useAuth from '../../hooks/useAuth';
//
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
import NavbarHorizontal from './navbar/NavbarHorizontal';
import Footer from './Footer';

import Image from '../../components/Image';
import palette from '../../theme/palette';

import {
  getAngPauPromotion,
  getGameCategsAndCompanies,
  getSiteSettings,
  getUserMemberBalance,
} from '../../redux/slices/lookup';

import useLocales from '../../hooks/useLocales';

import { setGameCategories, setActiveTab } from '../../redux/slices/game';

import conObj from '../../utils/connection-assistant';

import { usePageVisibility } from '../../hooks/usePageVisibility';
import WithdrawFormDialog from '../../views/withdraw/WithdrawFormDialog';
import PopupBanner from './PopupBanner';
import SubHeaderNav from '../../views/game/SubHeaderNav';
import { openLoginSignup, setIsPopupOpened } from '../../redux/slices/authPages';
import { closeLoadingScreen, openLoadingScreen } from '../../redux/slices/settings';

// ----------------------------------------------------------------------

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  background: `url(${require('../../assets/bg.jpeg')})`,
  backgroundSize: '100% auto',
  backgroundRepeat: 'repeat-y',
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  // background: palette.dark.background.default,
  paddingBottom: '64px',
  [theme.breakpoints.only('xs')]: {
    paddingTop: HEADER.MOBILE_HEIGHT,
  },
  [theme.breakpoints.up('lg')]: {
    // paddingLeft: 8,
    // paddingRight: 8,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    // paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    // ...(collapseClick && {
    //   marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    // }),
  },
}));

// ----------------------------------------------------------------------

const DashboardLayout = () => {
  const { isAuthenticated } = useAuth();

  const { collapseClick, isCollapse, onToggleCollapse } = useCollapseDrawer();

  const { isAddMemberBankDialog } = useSelector((x) => x.addMemberBankDialog);

  const { siteSettings } = useSelector((x) => x.lookup);

  const { isPopupOpened } = useSelector((x) => x.authPages);

  const isVisible = usePageVisibility();

  const { translate } = useLocales();

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { themeLayout } = useSettings();

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const verticalLayout = themeLayout === 'vertical';

  const location = useLocation();

  useEffect(() => {
    const promises = [getGameCategsAndCompanies(), getSiteSettings(), getAllGames(), getAngPauPromotion()];

    if (isAuthenticated) promises.push(getUserMemberBalance());

    dispatch(openLoadingScreen());
    Promise.all(promises).finally(() => dispatch(closeLoadingScreen()));
  }, [isAuthenticated]);

  useEffect(() => {
    dispatch(setIsPopupOpened(isPopupOpened + 1));
  }, []);

  /**
   * Call getUserMemberBalance() when tab is active and isAuthenticated
   * to keep the member balance updated
   */
  useEffect(() => {
    if (isVisible && isAuthenticated) getUserMemberBalance();
  }, [isVisible, isAuthenticated]);

  useEffect(() => {
    try {
      if (siteSettings?.config_analytics) {
        const _ga = siteSettings?.config_analytics?.split(',');

        // Multiple products (previously known as trackers)
        ReactGA.initialize(
          _ga.map((item) => ({
            // measurement ID
            trackingId: item,
            // gaOptions: {...}, // optional
            // gtagOptions: {...}, // optional
          }))
          //   [
          //   {
          //     // measurement ID
          //     trackingId: _ga[0],
          //     // gaOptions: {...}, // optional
          //     // gtagOptions: {...}, // optional
          //   },
          // ]
        );
      }

      // if (siteSettings?.config_livechat) {
      //   const respondIo = JSON.parse(siteSettings?.config_livechat);
      //   const script = document.createElement('script');

      //   script.id = respondIo?.id;
      //   script.src = respondIo?.src;
      //   script.async = true;

      //   document.head.appendChild(script);
      // }

      if (siteSettings?.config_fb_pixel) {
        const metaPixel = siteSettings.config_fb_pixel.replace(/[^\d,]+/g, '').split(',');
        const options = {
          autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
          debug: false, // enable logs
        };

        metaPixel.forEach((_pixel) => {
          ReactPixel.init(_pixel, options);

          ReactPixel.pageView(); // For tracking page view
        });
      }
    } catch (error) {
      console.warn(`3RD PARTY INTEGRATION ERROR: ${error?.message}`);
    }
  }, [siteSettings]);

  const getAllGames = () => {
    conObj.post('games/all.json?limit=1').then((res) => {
      if (res.status === 200) {
        // res.data?.data?.data
        // set appversion to localstorage
        // res.data?.data.appversion
        const _allGames = res.data?.data?.data;

        dispatch(
          setGameCategories(
            _allGames
              .filter((f) => f.key.toLowerCase().indexOf('gcat') !== -1)
              .map((item) => ({ key: item.key, name: item.name, slug: item.slug }))
          )
        );
      }
    });
  };

  if (verticalLayout) {
    return (
      <>
        <DashboardHeader onOpenSidebar={() => setOpen(true)} verticalLayout={verticalLayout} />

        {isDesktop ? (
          <NavbarHorizontal />
        ) : (
          <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        )}

        <Box
          component="main"
          sx={{
            px: { lg: 2 },
            pt: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
            },
            pb: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
            },
          }}
        >
          <Outlet />
        </Box>
      </>
    );
  }

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
        // backgroundColor: '#0b1712',
      }}
    >
      <DashboardHeader
        isCollapse={isCollapse}
        isOpenSidebar={open}
        onOpenSidebar={() => setOpen(true)}
        onCloseSidebar={() => setOpen(!open)}
        onToggleCollapse={onToggleCollapse}
      />

      <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <MainStyle collapseClick={collapseClick}>
        {location.pathname?.indexOf('/games') === -1 && <SubHeaderNav />}
        <Outlet />
        <Footer />
      </MainStyle>

      {/* BOTTOM NAVIGATION */}
      <Box
        sx={{
          width: '100%',
          height: '64px',
          backgroundColor: palette.bottomMenu.bgColor,
          position: 'fixed',
          bottom: 0,
          left: 0,
          zIndex: 100,
          boxShadow: '0 1px 2px -2px rgba(0,0,0,.64),0 3px 6px 0 rgba(0,0,0,.48),0 5px 12px 4px rgba(0,0,0,.36)',
          display: 'block',
          // p: '2px 0',
          right: 0,
        }}
      >
        <Grid container>
          <Grid
            onClick={() => setOpen(true)}
            item
            xs={12 / 5}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              flexDirection: 'column',
            }}
          >
            <Image
              src={require('../../assets/menu/menu.png')}
              alt="Menu"
              sx={{
                width: { md: '50px', sm: '30px', xs: '30px' },
                height: { md: '25px', sm: '15px', xs: '15px' },
                img: {
                  objectFit: 'contain !important',
                },
              }}
            />
            <Typography
              mt={1}
              sx={{
                fontSize: '11px',
                fontFamily: 'sans-serif',
                whiteSpace: 'nowrap',
                color: palette.bottomMenu.color,
                fontWeight: 600,
              }}
            >
              {translate('menu')}
            </Typography>
          </Grid>

          <Grid
            onClick={() => navigate(`/games/slots`)}
            item
            xs={12 / 5}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              flexDirection: 'column',
            }}
          >
            <Image
              src={require('../../assets/menu/nav-slot.png')}
              alt="Slot"
              sx={{
                width: { md: '50px', sm: '30px', xs: '30px' },
                height: { md: '25px', sm: '15px', xs: '15px' },
                img: {
                  objectFit: 'contain !important',
                },
              }}
            />
            <Typography
              mt={1}
              sx={{
                fontSize: '11px',
                fontFamily: 'sans-serif',
                whiteSpace: 'nowrap',
                color: palette.bottomMenu.color,
                fontWeight: 600,
              }}
            >
              {translate('slots')}
            </Typography>
          </Grid>
          {/* DEPOSIT */}
          <Grid
            item
            xs={12 / 5}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              flexDirection: 'column',
            }}
          >
            <Box
              onClick={() => {
                if (!isAuthenticated) dispatch(openLoginSignup({ open: true, isLogin: true }));
                else navigate('user/wallet');
              }}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: palette.bottomMenu.depositBg,
                flexDirection: 'column',
                height: '68px',
                // marginTop: '-20px',
                width: '68px',
                borderRadius: '100%',
                cursor: 'pointer',
              }}
            >
              <Image
                src={require('../../assets/home/deposit.png')}
                alt="Deposit"
                sx={{ height: '46px', width: '46px', objectFit: 'contain' }}
              />
              <Typography
                sx={{ fontFamily: 'sans-serif', fontSize: '11px', fontWeight: 700, color: '#fff' }}
                className="deposit-text-animation"
              >
                {translate('deposit')}
              </Typography>
            </Box>
          </Grid>
          {/* LIVE CASINO */}
          <Grid
            item
            xs={12 / 5}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              flexDirection: 'column',
            }}
            onClick={() => navigate('/games/live-casino')}
          >
            <Image
              src={require('../../assets/menu/nav-live.png')}
              alt="Live Casino"
              sx={{
                width: { md: '50px', sm: '30px', xs: '30px' },
                height: { md: '25px', sm: '15px', xs: '15px' },
                img: {
                  objectFit: 'contain !important',
                },
              }}
            />
            <Typography
              mt={1}
              sx={{ fontSize: '11px', fontFamily: 'sans-serif', whiteSpace: 'nowrap', color: 'white', fontWeight: 600 }}
            >
              {translate('casino')}
            </Typography>
          </Grid>
          {/* VIP CLUB */}
          <Grid
            item
            xs={12 / 5}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              flexDirection: 'column',
            }}
            onClick={() => {
              navigate('/vip');
            }}
          >
            <Image
              src={require('../../assets/menu/nav-vip.png')}
              alt="Vip Club"
              sx={{
                width: { md: '50px', sm: '30px', xs: '30px' },
                height: { md: '25px', sm: '15px', xs: '15px' },
                img: {
                  objectFit: 'contain !important',
                },
              }}
            />
            <Typography
              mt={1}
              sx={{ fontSize: '11px', fontFamily: 'sans-serif', whiteSpace: 'nowrap', color: 'white', fontWeight: 600 }}
            >
              {translate('vip_club')}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      {siteSettings?.config_livechat && siteSettings?.config_livechat?.length === 8 ? (
        <LiveChatWidget
          license={siteSettings?.config_livechat}
          onReady={() => {
            const widgetContainer = document.getElementById('chat-widget-container');

            if (widgetContainer) {
              widgetContainer.classList.add('adjust-bottom-space');
            }
          }}
          onVisibilityChanged={(data) => {
            const widgetContainer = document.getElementById('chat-widget-container');

            if (widgetContainer) {
              if (data.visibility === 'maximized') {
                widgetContainer.classList.remove('adjust-bottom-space');
              } else widgetContainer.classList.add('adjust-bottom-space');
            }
          }}
        />
      ) : (
        <></>
      )}

      {isAddMemberBankDialog?.open && <WithdrawFormDialog />}

      {siteSettings?.popups?.length > 0 && isPopupOpened <= siteSettings?.popups?.length
        ? [...siteSettings?.popups].reverse().map((item, index) => <PopupBanner key={index} popupBanner={item} />)
        : ''}
    </Box>
  );
};

export default DashboardLayout;
