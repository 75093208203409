import { Box, Tab, Typography, styled } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { TabContext, TabList } from '@mui/lab';
import { useLocation, useNavigate, useParams } from 'react-router';
import Image from '../../components/Image';
import palette from '../../theme/palette';
import useLocales from '../../hooks/useLocales';
import { useDispatch, useSelector } from '../../redux/store';
import Iconify from '../../components/Iconify';
import useAuth from '../../hooks/useAuth';
import { openLoginSignup } from '../../redux/slices/authPages';

const getGameCateg = (slug) => {
  let nav = '';

  if (slug.indexOf('slots') !== -1) nav = { src: require('../../assets/menu/nav-slot.png') };

  if (slug.indexOf('live-casino') !== -1) nav = { src: require('../../assets/menu/nav-live.png') };

  if (slug.indexOf('fishing') !== -1) nav = { src: require('../../assets/menu/nav-fish.png') };

  if (slug.indexOf('card') !== -1) nav = { src: require('../../assets/menu/nav-card.png') };

  if (slug.indexOf('sports') !== -1) nav = { icon: 'ri:football-fill' };

  if (slug.indexOf('e-sports') !== -1) nav = { src: require('../../assets/menu/nav-e-sports.png') };

  if (slug.indexOf('instant-game') !== -1) nav = { src: require('../../assets/menu/nav-instant.png') };

  return nav;
};

const SubHeaderContainer = styled(Box)(() => ({
  boxShadow: '0px 1px 5px 5px rgb(0, 0, 0, 0.2)',
  height: '3rem',
  background:
    'linear-gradient(90deg, rgba(240,26,26,1) 0%, rgba(242,42,42,1) 15%, rgba(255,168,168,1) 20%, rgba(255,168,168,1) 30%, rgba(240,26,26,1) 35%, rgba(240,26,26,1) 40%, rgba(255,168,168,1) 45%, rgba(255,168,168,1) 55%, rgba(240,26,26,1) 60%, rgba(240,26,26,1) 65%, rgba(255,168,168,1) 70%, rgba(255,168,168,1) 80%, rgba(240,26,26,1) 85%)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  width: '100%',
  zIndex: 1,
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    left: '0px',
    right: '0px',
    top: '2px',
    bottom: '2px',
    color: palette.visitor.login.color,
    background: palette.navBarVertical.subBg,
    zIndex: -1,
  },
  '&::after': {
    content: 'attr(data)',
    backgroundClip: 'text',
    color: palette.visitor.login.color,
  },
}));

const SubHeaderNav = () => {
  const { translate } = useLocales();

  const { isAuthenticated } = useAuth();

  const { gameCategsAndCompanies } = useSelector((x) => ({ ...x.game, ...x.lookup }));

  const dispatch = useDispatch();

  const [activeSubTab, setActiveSubTab] = useState('lobby');

  const navigate = useNavigate();

  const params = useParams();

  const location = useLocation();

  const navButtons = useMemo(() => {
    const _navButtons = [
      {
        title: translate('lobby'),
        value: 'lobby',
        imageSrc: { src: require('../../assets/menu/nav-home.png') },
        action: () => {
          setActiveSubTab('lobby');
          navigate('/home');
        },
      },
      {
        title: translate('vip_club'),
        value: 'vip',
        imageSrc: { src: require('../../assets/menu/nav-vip.png') },
        action: () => {
          if (!isAuthenticated) dispatch(openLoginSignup({ open: true, isLogin: true }));
          else {
            setActiveSubTab('vip');
            navigate('/vip');
          }
        },
      },
    ];

    if (gameCategsAndCompanies?.length) {
      gameCategsAndCompanies.forEach((categ) => {
        _navButtons.push({
          title: categ?.name,
          value: categ?.slug,
          imageSrc: getGameCateg(categ?.slug),
          action: () => onCategClick(categ?.slug),
        });
      });
    }

    return _navButtons;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameCategsAndCompanies]);

  useEffect(() => {
    if (params?.categSlug) {
      setActiveSubTab(params?.categSlug);
    }
  }, [params]);

  useEffect(() => {
    if (location.pathname === '/vip') {
      setActiveSubTab('vip');
    }
  }, [location]);

  const onCategClick = (slug) => {
    setActiveSubTab(slug);
    navigate(`/games/${slug}`);
  };

  const StyledContainer = styled(Box)(() => ({
    width: '100%',
    display: 'flex',
    borderTop: '2px solid',
    borderBottom: '2px solid',
    padding: 5,
    borderImage:
      'linear-gradient(to right, #FFA8A8, #FEAAAA, #F01A1A, #FFB3B3, #FEADAD, #F11A1A, #FFB3B3, #FFA8A8) 1 1 1 1',
  }));

  return (
    <SubHeaderContainer>
      <Box sx={{ width: '100%', maxWidth: '1200px', overflow: 'hidden' }}>
        <TabContext value={activeSubTab}>
          <StyledContainer sx={{ justifyContent: { sm: 'center' } }}>
            <TabList
              TabIndicatorProps={{
                style: { backgroundColor: 'transparent' },
              }}
              sx={{
                minHeight: 'unset',
                color: 'white',
                flex: { xs: 1, md: 'none' },
              }}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
              TabScrollButtonProps={{
                sx: {
                  width: '0.5rem',
                  borderRadius: 0,
                },
              }}
            >
              {navButtons.map((nav, idx) => (
                <Tab
                  key={idx}
                  sx={{
                    justifyContent: 'center',
                    marginRight: idx !== navButtons?.length - 1 ? '0.4rem !important' : 0,
                    cursor: 'pointer',
                    minHeight: 'unset',
                    minWidth: 'unset',
                    flexGrow: 1,
                    p: 1,
                  }}
                  onClick={nav.action}
                  label={
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        height: '100%',
                        flex: 1,
                      }}
                    >
                      <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                        {nav?.imageSrc?.src ? (
                          <Box sx={{ width: '1.2rem' }}>
                            <Image
                              src={nav?.imageSrc?.src}
                              sx={{ width: '20px', height: '20px', img: { objectFit: 'contain' } }}
                            />
                          </Box>
                        ) : (
                          <Iconify icon={nav.imageSrc?.icon} sx={{ fontSize: '1.2rem' }} />
                        )}
                      </Box>
                      <Typography fontSize={'0.6rem'} fontWeight={900} sx={{ whiteSpace: 'nowrap', mt: 0.3 }}>
                        {translate(nav.title)}
                      </Typography>
                    </Box>
                  }
                  value={nav.value}
                />
              ))}
            </TabList>
          </StyledContainer>
        </TabContext>
      </Box>
    </SubHeaderContainer>
  );
};

export default SubHeaderNav;
