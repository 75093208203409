import { useEffect, useState } from 'react';
// import { enUS, frFR, zhCN, viVN, arSD } from '@mui/material/locale';
// @mui
import { Button, MenuItem, Stack, Typography } from '@mui/material';
// hooks
import useLocales from '../../../hooks/useLocales';
import { useSelector } from '../../../redux/store';
// components
import Image from '../../../components/Image';
import MenuPopover from '../../../components/MenuPopover';

// ----------------------------------------------------------------------

// Put this outside so it won't be affected for any react state change
const isUserFirstTimeVisit = localStorage.getItem('userVisited') !== '1';
export default function LanguagePopover({ sx = {} }) {
  const { allLangs, currentLang, onChangeLang } = useLocales();

  const { siteSettings } = useSelector((x) => x.lookup);

  const [customLangs, setCustomLangs] = useState([]);

  const [open, setOpen] = useState(null);

  // const [selectedLang, setSelectedLang] = useState(currentLang);

  const domainLocale = process.env.REACT_APP_LOCALE;

  const langStorage = localStorage.getItem('i18nextLng');

  useEffect(() => {
    if (siteSettings?.languages?.length > 0) {
      // const matchingLangs = allLangs.filter((obj1) =>
      //   siteSettings?.languages.some((obj2) => obj1.value === obj2.locale)
      // );
      const matchingLangs = allLangs;

      if (matchingLangs) {
        setCustomLangs(matchingLangs);

        if (matchingLangs.findIndex((ml) => ml.value === langStorage) === -1) {
          handleChangeLang(matchingLangs.find((f) => f.value === domainLocale)?.value);
          console.log('matching');
        }
      } else {
        console.log('No Matching languages');
      }
    }
  }, [siteSettings]);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleChangeLang = (newLang) => {
    onChangeLang(newLang);
    handleClose();
    window.location.reload();
  };

  return (
    <>
      <Button
        variant={'text'}
        onClick={handleOpen}
        startIcon={
          <Image disabledEffect src={currentLang?.icon} alt={currentLang?.label} sx={{ width: 28, height: 20 }} />
        }
        sx={sx}
        size="small"
      >
        <Typography fontWeight={700} sx={{ color: '#fff' }}>
          {currentLang?.value?.toUpperCase()}
        </Typography>
      </Button>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <Stack spacing={0.75}>
          {customLangs?.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLang.value}
              onClick={() => {
                handleChangeLang(option.value);
                // setSelectedLang(option);
              }}
            >
              <Image disabledEffect alt={option.label} src={option.icon} sx={{ width: 28, height: 20, mr: 2 }} />

              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
